require('./bootstrap');

require('alpinejs');
require('trix/dist/trix-core.js');
// require('trix/dist/trix.js');
require('trix/dist/trix.css');



// var element = document.querySelector(".trix-editor")
// element.editor 

// console.log(element);